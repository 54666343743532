import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contactContainer">
        <p>
      Mobile no: 9226724260<br></br><br></br>
      Mail: tzmart92@gmail.com<br></br><br></br>
      Adress: Kat Kat Gate, Aurangabad
      </p>
    </div>
  );
};

export default Contact;