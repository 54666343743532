import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SideBar from "./Sidebar";
import {
  getOrderDetails,
  clearErrors,
  updateOrder,
} from "../../actions/orderAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Button } from "@material-ui/core";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import "./processOrder.css";
import jsPDF from "jspdf";
import QRCode from "qrcode.react";

const ProcessOrder = ({ history, match }) => {
  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);

  const updateOrderSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("status", status);

    dispatch(updateOrder(match.params.id, myForm));
  };

  const dispatch = useDispatch();
  const alert = useAlert();

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Order Updated Successfully");
      dispatch({ type: UPDATE_ORDER_RESET });
    }

    dispatch(getOrderDetails(match.params.id));
  }, [dispatch, alert, error, match.params.id, isUpdated, updateError]);

  const downloadPDF = () => {
    const pdf = new jsPDF();
    const qrCodeDataURL = document
      .getElementById("qrCode")
      .toDataURL("image/png");
    pdf.addImage(qrCodeDataURL, "PNG", 10, 10, 30, 30); // Adjust the position and size as needed

    // Add content to the PDF
    pdf.text("Shipping Info:", 50, 20); // Adjust the position as needed
    pdf.text(`Name: ${order.user && order.user.name}`, 50, 30);
    pdf.text(
      `Phone: ${order.shippingInfo && order.shippingInfo.phoneNo}`,
      50,
      40
    );
    pdf.text(
      `Address: ${
        order.shippingInfo &&
        `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`
      }`,
      50,
      50
    );

    pdf.text("Payment:", 10, 60);
    pdf.text(
      `Status: ${
        order.paymentInfo &&
        (order.paymentInfo.status === "succeeded" ? "PAID" : "NOT PAID")
      }`,
      10,
      70
    );
    pdf.text(`Amount: ${order.totalPrice && order.totalPrice}`, 10, 80);

    pdf.text("Order Status:", 10, 100);
    pdf.text(`Status: ${order.orderStatus && order.orderStatus}`, 10, 110);

    pdf.text("Your Cart Items:", 10, 130);
    let yPosition = 140;
    order.orderItems &&
      order.orderItems.forEach((item) => {
        pdf.text(
          `${item.name} (${item.quantity} X ₹${item.price}): ₹${
            item.price * item.quantity
          }`,
          10,
          yPosition
        );
        yPosition += 10;
      });

    // Save the PDF
    pdf.save("order_details.pdf");
  };

  return (
    <Fragment>
      <MetaData title="Process Order" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">
          {loading ? (
            <Loader />
          ) : (
            <div
              className="confirmOrderPage"
              style={{
                display: order.orderStatus === "Delivered" ? "block" : "grid",
              }}
            >
              <QRCode
                id="qrCode"
                value="Your QR Code Data" // You need to replace this with your actual data
                size={60} // Adjust the size as needed
                level="H"
                includeMargin={false}
              />

              <Button
                id="downloadPDFBtn"
                onClick={downloadPDF}
                disabled={loading}
                style={{
                  marginBottom: "40px",
                  marginLeft: "40px",
                  backgroundColor: "lightgreen",
                  fontSize: "12px",
                  padding: "6px 12px",
                }}
              >
                Download PDF
              </Button>
              <div>
                <div className="confirmshippingArea">
                  <Typography>Shipping Info</Typography>
                  <div className="orderDetailsContainerBox">
                    <div>
                      <p>Name:</p>
                      <span>{order.user && order.user.name}</span>
                    </div>
                    <div>
                      <p>Phone:</p>
                      <span>
                        {order.shippingInfo && order.shippingInfo.phoneNo}
                      </span>
                    </div>
                    <div>
                      <p>Address:</p>
                      <span>
                        {order.shippingInfo &&
                          `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                      </span>
                    </div>
                  </div>

                  <Typography>Payment</Typography>
                  <div className="orderDetailsContainerBox">
                    <div>
                      <p
                        className={
                          order.paymentInfo &&
                          order.paymentInfo.status === "succeeded"
                            ? "greenColor"
                            : "redColor"
                        }
                      >
                        {order.paymentInfo &&
                        order.paymentInfo.status === "succeeded"
                          ? "PAID"
                          : "NOT PAID"}
                      </p>
                    </div>

                    <div>
                      <p>Amount:</p>
                      <span>{order.totalPrice && order.totalPrice}</span>
                    </div>
                  </div>

                  <Typography>Order Status</Typography>
                  <div className="orderDetailsContainerBox">
                    <div>
                      <p
                        className={
                          order.orderStatus && order.orderStatus === "Delivered"
                            ? "greenColor"
                            : "redColor"
                        }
                      >
                        {order.orderStatus && order.orderStatus}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="confirmCartItems">
                  <Typography>Your Cart Items:</Typography>
                  <div className="confirmCartItemsContainer">
                    {order.orderItems &&
                      order.orderItems.map((item) => (
                        <div key={item.product}>
                          <img src={item.image} alt="Product" />
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>{" "}
                          <span>
                            {item.quantity} X ₹{item.price} ={" "}
                            <b>₹{item.price * item.quantity}</b>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {/*  */}
              <div
                style={{
                  display: order.orderStatus === "Delivered" ? "none" : "block",
                }}
              >
                <form
                  className="updateOrderForm"
                  onSubmit={updateOrderSubmitHandler}
                >
                  <h1>Process Order</h1>

                  <div>
                    <AccountTreeIcon />
                    <select onChange={(e) => setStatus(e.target.value)}>
                      <option value="">Choose Category</option>
                      {order.orderStatus === "Processing" && (
                        <option value="Shipped">Shipped</option>
                      )}

                      {order.orderStatus === "Shipped" && (
                        <option value="Delivered">Delivered</option>
                      )}
                    </select>
                  </div>

                  <Button
                    id="createProductBtn"
                    type="submit"
                    disabled={
                      loading ? true : false || status === "" ? true : false
                    }
                  >
                    Process
                  </Button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ProcessOrder;

// import React, { Fragment, useEffect, useState } from "react";
// import MetaData from "../layout/MetaData";
// import { Link } from "react-router-dom";
// import { Typography } from "@material-ui/core";
// import SideBar from "./Sidebar";
// import {
//   getOrderDetails,
//   clearErrors,
//   updateOrder,
// } from "../../actions/orderAction";
// import { useSelector, useDispatch } from "react-redux";
// import Loader from "../layout/Loader/Loader";
// import { useAlert } from "react-alert";
// import AccountTreeIcon from "@material-ui/icons/AccountTree";
// import { Button } from "@material-ui/core";
// import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
// import "./processOrder.css";

// const ProcessOrder = ({ history, match }) => {
//   const { order, error, loading } = useSelector((state) => state.orderDetails);
//   const { error: updateError, isUpdated } = useSelector((state) => state.order);

//   const updateOrderSubmitHandler = (e) => {
//     e.preventDefault();

//     const myForm = new FormData();

//     myForm.set("status", status);

//     dispatch(updateOrder(match.params.id, myForm));
//   };

//   const dispatch = useDispatch();
//   const alert = useAlert();

//   const [status, setStatus] = useState("");

//   useEffect(() => {
//     if (error) {
//       alert.error(error);
//       dispatch(clearErrors());
//     }
//     if (updateError) {
//       alert.error(updateError);
//       dispatch(clearErrors());
//     }
//     if (isUpdated) {
//       alert.success("Order Updated Successfully");
//       dispatch({ type: UPDATE_ORDER_RESET });
//     }

//     dispatch(getOrderDetails(match.params.id));
//   }, [dispatch, alert, error, match.params.id, isUpdated, updateError]);

//   return (
//     <Fragment>
//       <MetaData title="Process Order" />
//       <div className="dashboard">
//         <SideBar />
//         <div className="newProductContainer">
//           {loading ? (
//             <Loader />
//           ) : (
//             <div
//               className="confirmOrderPage"
//               style={{
//                 display: order.orderStatus === "Delivered" ? "block" : "grid",
//               }}
//             >
//               <div>
//                 <div className="confirmshippingArea">
//                   <Typography>Shipping Info</Typography>
//                   <div className="orderDetailsContainerBox">
//                     <div>
//                       <p>Name:</p>
//                       <span>{order.user && order.user.name}</span>
//                     </div>
//                     <div>
//                       <p>Phone:</p>
//                       <span>
//                         {order.shippingInfo && order.shippingInfo.phoneNo}
//                       </span>
//                     </div>
//                     <div>
//                       <p>Address:</p>
//                       <span>
//                         {order.shippingInfo &&
//                           `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
//                       </span>
//                     </div>
//                   </div>

//                   <Typography>Payment</Typography>
//                   <div className="orderDetailsContainerBox">
//                     <div>
//                       <p
//                         className={
//                           order.paymentInfo &&
//                           order.paymentInfo.status === "succeeded"
//                             ? "greenColor"
//                             : "redColor"
//                         }
//                       >
//                         {order.paymentInfo &&
//                         order.paymentInfo.status === "succeeded"
//                           ? "PAID"
//                           : "NOT PAID"}
//                       </p>
//                     </div>

//                     <div>
//                       <p>Amount:</p>
//                       <span>{order.totalPrice && order.totalPrice}</span>
//                     </div>
//                   </div>

//                   <Typography>Order Status</Typography>
//                   <div className="orderDetailsContainerBox">
//                     <div>
//                       <p
//                         className={
//                           order.orderStatus && order.orderStatus === "Delivered"
//                             ? "greenColor"
//                             : "redColor"
//                         }
//                       >
//                         {order.orderStatus && order.orderStatus}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="confirmCartItems">
//                   <Typography>Your Cart Items:</Typography>
//                   <div className="confirmCartItemsContainer">
//                     {order.orderItems &&
//                       order.orderItems.map((item) => (
//                         <div key={item.product}>
//                           <img src={item.image} alt="Product" />
//                           <Link to={`/product/${item.product}`}>
//                             {item.name}
//                           </Link>{" "}
//                           <span>
//                             {item.quantity} X ₹{item.price} ={" "}
//                             <b>₹{item.price * item.quantity}</b>
//                           </span>
//                         </div>
//                       ))}
//                   </div>
//                 </div>
//               </div>
//               {/*  */}
//               <div
//                 style={{
//                   display: order.orderStatus === "Delivered" ? "none" : "block",
//                 }}
//               >
//                 <form
//                   className="updateOrderForm"
//                   onSubmit={updateOrderSubmitHandler}
//                 >
//                   <h1>Process Order</h1>

//                   <div>
//                     <AccountTreeIcon />
//                     <select onChange={(e) => setStatus(e.target.value)}>
//                       <option value="">Choose Category</option>
//                       {order.orderStatus === "Processing" && (
//                         <option value="Shipped">Shipped</option>
//                       )}

//                       {order.orderStatus === "Shipped" && (
//                         <option value="Delivered">Delivered</option>
//                       )}
//                     </select>
//                   </div>

//                   <Button
//                     id="createProductBtn"
//                     type="submit"
//                     disabled={
//                       loading ? true : false || status === "" ? true : false
//                     }
//                   >
//                     Process
//                   </Button>
//                 </form>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default ProcessOrder;
