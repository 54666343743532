import React from "react";

const Privacy = () => {
  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "10px", // Adding margin bottom for spacing between headings
    marginTop: "20px", // Adding margin top for spacing from the top
  };

  const paragraphStyle = {
    marginBottom: "20px", // Adding margin bottom for spacing between paragraphs
    lineHeight: "1.5", // Adjusting line height for readability
  };

  return (
      <div
        className="privacy-policy"
        style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
      >
        <h1 style={headingStyle}>Privacy Policy</h1>
        <p style={paragraphStyle}>
          At www.tzmart.in, accessible from
          www.tzmart.in, This page informs you of our policies
          regarding the collection, use and disclosure of Personal Information
          when you use our Service. We will not use or share your information
          with anyone except as described in this Privacy Policy. We use your
          Personal Information for providing and improving the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions, accessible at www.tzmart.in
        </p>
        <h2 style={headingStyle}>Information Collection and Use</h2>
        <p style={paragraphStyle}>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to, your email address, name, phone number, postal
          address, other information (“Personal Information”). We collect this
          information for the purpose of providing the Service, identifying and
          communicating with you, responding to your requests/inquiries,
          servicing your purchase orders, and improving our services.
        </p>
        <h2 style={headingStyle}>Log Data</h2>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service (“Log Data”). This Log Data may include information
          such as your computer’s Internet Protocol (“IP”) address, browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages and other
          statistics. In addition, we may use third party services such as
          Google Analytics that collect, monitor and analyze this type of
          information in order to increase our Service’s functionality. These
          third party service providers have their own privacy policies
          addressing how they use such information.
        </p>
        <h2 style={headingStyle}>Cookies</h2>
        <p>
          Cookies are files with a small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          web site and transferred to your device. We use cookies to collect
          information in order to improve our services for you. You can instruct
          your browser to refuse all cookies or to indicate when a cookie is
          being sent. The Help feature on most browsers provide information on
          how to accept cookies, disable cookies or to notify you when receiving
          a new cookie.
          <br />
          If you do not accept cookies, you may not be able to use some features
          of our Service and we recommend that you leave them turned on.
        </p>
      

        

       

        <h2 style={headingStyle}>Service Providers</h2>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service, to provide the Service on our behalf, to perform
          Service-related services and/or to assist us in analyzing how our
          Service is used. These third parties have access to your Personal
          Information only to perform specific tasks on our behalf and are
          obligated not to disclose or use your information for any other
          purpose.
        </p>

        <h2 style={headingStyle}>Communications</h2>
        <p>
          We may use your Personal Information to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or
          instructions provided in any email we send.
        </p>

        
        <h2 style={headingStyle}>Business Transaction</h2>
        <p>
          If Tz MART is involved in a merger, acquisition or asset
          sale, your Personal Information may be transferred as a business
          asset. In such cases, we will provide notice before your Personal
          Information is transferred and/or becomes subject to a different
          Privacy Policy.
        </p>

        <h2 style={headingStyle}>Security</h2>
        <p>
          The security of your Personal Information is important to us, and we
          strive to implement and maintain reasonable, commercially acceptable
          security procedures and practices appropriate to the nature of the
          information we store, in order to protect it from unauthorized access,
          destruction, use, modification, or disclosure. However, please be
          aware that no method of transmission over the internet, or method of
          electronic storage is secure and we are unable to guarantee the
          absolute security of the Personal Information we have collected from
          you.
        </p>

        

        <h2 style={headingStyle}>Links To Other Sites</h2>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over, and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </p>

        <h2 style={headingStyle}>Children’s Privacy</h2>
        <p>
          Only persons have permission to access our Service.
          We do not knowingly collect personally identifiable information from
          children under 13. If you are a parent or guardian and you learn that
          your Children have provided us with Personal Information, please
          contact us. We take steps to remove that information from our
          servers.
        </p>

       

        <h2 style={headingStyle}>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us.
        </p>
      </div>
  );
};

export default Privacy;
