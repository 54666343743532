import React, { Fragment } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import "./ConfirmOrder.css";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import jsPDF from "jspdf";
import QRCode from "qrcode.react";

const ConfirmOrder = ({ history }) => {
  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);

  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  // const shippingCharges = subtotal > 1000 ? 0 : 200;
  let shippingCharges = 0;

  if (subtotal < 1300) {
    shippingCharges = 50;
  } else if (subtotal >= 1300 && subtotal < 2600) {
    shippingCharges = 15;
  } else {
    shippingCharges = 0;
  }

  const tax = subtotal * 0;

  const totalPrice = subtotal + shippingCharges;

  const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;

  const proceedToPayment = () => {
    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));

    history.push("/process/payment");
  };

  const openWhatsApp = () => {
    const whatsappNumber = '+919226724260'; // Replace this with your specific WhatsApp number
    // Shipping Information
  const name = user.name;
  const phone = shippingInfo.phoneNo;
  const address =`${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;
  let message = `New Order:\n\nShipping Info:\nName: ${name}\nPhone: ${phone}\nAddress: ${address}\n\n`;

  // Cart Items Details
  message += 'Your Cart Items:\n';
  cartItems.forEach(item => {
    message += `${item.name} (${item.quantity} X Rs ${item.price}): Rs ${item.price * item.quantity}\n\n`;
  });

  // Order Summary
  message += `Order Summary:\nSubtotal: Rs ${subtotal}\nShipping Charges: Rs ${shippingCharges}\nTotal: Rs ${totalPrice}`;

  // Construct the WhatsApp link with the message
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

  // Open WhatsApp link in a new tab or window
  window.open(whatsappLink, '_blank');
    
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();
    // const qrCodeDataURL = document
    //   .getElementById("qrCode")
    //   .toDataURL("image/logott.png");
    // pdf.addImage(qrCodeDataURL, "PNG", 10, 10, 30, 30); // Adjust the position and size as needed

    // Add content to the PDF
    pdf.addImage("/image/logott.png", "PNG", 10, 10, 30, 28);
    pdf.text("Shipping Info:", 50, 20); // Adjust the position as needed
    pdf.text(`Name: ${user.name}`, 50, 30);
    pdf.text(`Phone: ${shippingInfo.phoneNo}`, 50, 40);
    pdf.text(`Address: ${address}`, 50, 50);

    pdf.text("Your Cart Items:", 10, 60);
    let yPosition = 70;
    cartItems &&
      cartItems.forEach((item) => {
        pdf.text(
          `${item.name} (${item.quantity} X Rs ${item.price}): Rs ${
            item.price * item.quantity
          }`,
          10,
          yPosition
        );
        yPosition += 10;
      });

    pdf.text("Order Summary:", 10, yPosition + 10);
    pdf.text(`Subtotal: Rs ${subtotal}`, 10, yPosition + 20);
    pdf.text(`Shipping Charges: ₹${shippingCharges}`, 10, yPosition + 30);
    pdf.text(`GST: ₹${tax}`, 10, yPosition + 40);

    pdf.text("Total:", 10, yPosition + 50);
    pdf.text(`Rs ${totalPrice}`, 10, yPosition + 60);

    // Save the PDF
    pdf.save("order_details.pdf");
    openWhatsApp();
  };

  return (
    <Fragment>
      <MetaData title="Confirm Order" />
      <CheckoutSteps activeStep={1} />
      <div className="confirmOrderPage">
        <div>
          <div className="confirmshippingArea">
            <p style={{ margin: "8px" }}>
              1: If purchase total price is below 1300 Rs = 50 Rs{" "}
            </p>
            <p style={{ margin: "8px" }}>
              2: If purchase total price is above 1300 Rs = 25 Rs{" "}
            </p>
            <p style={{ margin: "8px" }}>
              3: If purchase total price is above 2600 Rs = Free{" "}
            </p>
            <Typography>Shipping Info</Typography>
            <div className="confirmshippingAreaBox">
              <div>
                <p>Name:</p>
                <span>{user.name}</span>
              </div>
              <div>
                <p>Phone:</p>
                <span>{shippingInfo.phoneNo}</span>
              </div>
              <div>
                <p>Address:</p>
                <span>{address}</span>
              </div>
            </div>
          </div>
          <div className="confirmCartItems">
            <Typography>Your Cart Items:</Typography>
            <div className="confirmCartItemsContainer">
              {cartItems &&
                cartItems.map((item) => (
                  <div key={item.product}>
                    <img src={item.image} alt="Product" />
                    <Link to={`/product/${item.product}`}>
                      {item.name}
                    </Link>{" "}
                    <span>
                      {item.quantity} X ₹{item.price} ={" "}
                      <b>₹{item.price * item.quantity}</b>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          {/* <QRCode
            id="qrCode"
            value="Your QR Code Data" // You need to replace this with your actual data
            size={60} // Adjust the size as needed
            level="H"
            includeMargin={false}
          /> */}
          <p>Tz MART</p>

          <img
            src="/image/logott.png"
            alt=""
            width="80"
            height="78"
            style={{ margin: 20 }}
          />

          <div className="orderSummary">
            <Typography>Order Summery</Typography>
            <div>
              <div>
                <p>Subtotal:</p>
                <span>₹{subtotal}</span>
              </div>
              <div>
                <p>Shipping Charges:</p>
                <span>₹{shippingCharges}</span>
              </div>
              {/* <div>
                <p>GST:</p>
                <span>₹{tax}</span>
              </div> */}
            </div>

            <div className="orderSummaryTotal">
              <p>
                <b>Total:</b>
              </p>
              <span>₹{totalPrice}</span>
            </div>

            <button onClick={proceedToPayment}>Proceed To Payment</button>
            <button
              id="downloadPDFBtn"
              onClick={downloadPDF}
              disabled={loading}
              className="my-2"
            >
              Cash on Delivary
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmOrder;
