import React from "react";

const Condition = () => {
  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "10px", // Adding margin bottom for spacing between headings
    marginTop: "20px", // Adding margin top for spacing from the top
  };

  const paragraphStyle = {
    marginBottom: "20px", // Adding margin bottom for spacing between paragraphs
    lineHeight: "1.5", // Adjusting line height for readability
  };

  return (
    <div
      className="privacy-policy"
      style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
    >
      <h1 style={headingStyle}>Terms-of-service</h1>
      <p style={paragraphStyle}>
        Please read these Terms-of-service(“Terms”, “Terms-of-service”)
        carefully before using tzmart.in website (the “Service”) operated by tz
        mart. Your access to and use of the Service is
        conditioned upon your acceptance of and compliance with these Terms.
        These Terms apply to all visitors, users and others who wish to access
        or use the Service. By accessing or using the Service you agree to be
        bound by these Terms. If you disagree with any part of the terms then
        you do not have permission to access the Service. We use your Personal
        Information for providing and improving the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions, accessible at tzmart.in
      </p>
      <h2 style={headingStyle}>Communications</h2>
      <p style={paragraphStyle}>
        By creating an Account on our service, you agree to subscribe to
        newsletters, marketing or promotional materials and other information we
        may send. However, you may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send.
      </p>

      <h2 style={headingStyle}>Availability, Errors and Inaccuracies</h2>
      <p>
        We are constantly updating product and service offerings on the Service.
        We may experience delays in updating information on the Service and in
        our advertising on other web sites. The information found on the Service
        may contain errors or inaccuracies and may not be complete or current.
        Products or services may be mispriced, described inaccurately, or
        unavailable on the Service and we cannot guarantee the accuracy or
        completeness of any information found on the Service. We therefore
        reserve the right to change or update information and to correct errors,
        inaccuracies, or omissions at any time without prior notice.
        <br />
        If you do not accept cookies, you may not be able to use some features
        of our Service and we recommend that you leave them turned on.
      </p>

      <h2 style={headingStyle}>Refunds</h2>
      <p>
        If any then it is refundable.
      </p>

      <h2 style={headingStyle}>Accounts</h2>
      <p>
        When you create an account with us, you guarantee that you are minded, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        the Service. You are responsible for maintaining the confidentiality of
        your account and password, including but not limited to the restriction
        of access to your computer and/or account. You agree to accept
        responsibility for any and all activities or actions that occur under
        your account and/or password, whether your password is with our Service
        or a third-party service. You must notify us immediately upon becoming
        aware of any breach of security or unauthorized use of your account. You
        may not use as a username the name of another person or entity or that
        is not lawfully available for use, a name or trademark that is subject
        to any rights of another person or entity other than you, without
        appropriate authorization. You may not use as a username any name that
        is offensive, vulgar or obscene. We reserve the right to refuse service,
        terminate accounts, remove or edit content, or cancel orders in our sole
        discretion.
      </p>

      <h2 style={headingStyle}>Links To Other Sites</h2>
      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by tz mart. tz mart has no control over, and
        assumes no responsibility for the content, privacy policies, or
        practices of any third party web sites or services. We do not warrant
        the offerings of any of these entities/individuals or their websites.
        You acknowledge and agree that tz mart shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with use of or reliance on any such
        content, goods or services available on or through any such third party
        web sites or services. We strongly advise you to read the terms and
        conditions and privacy policies of any third party web sites or services
        that you visit.
      </p>

      <h2 style={headingStyle}>Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will provide notice prior to any new terms taking effect. What constitutes a 
        material change will be determined at our sole discretion. By continuing
        to access or use our Service after any revisions become effective, you
        agree to be bound by the revised terms. If you do not agree to the new
        terms, you are no longer authorized to use the Service.
      </p>

      <h2 style={headingStyle}>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us.</p>
    </div>
  );
};

export default Condition;
