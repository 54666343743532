import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import "../Home/Home.css";
import "./Home.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import Offer from "../Home/Offer.js";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/MetaData";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const categories = [

  "DailyNeed Items",
  "Crockery",
  "toys",
  "Plastics Appliance",
  "Dryfruit",
  "Cosmetic",
  "Baby",
  "Grocery",
];

const Products = ({ match }) => {
  const slidee = [
    "inside4.jpg",
    "inside3.jpg",
    "inside1.jpg",
    "inside6.jpg",
    "inside5.jpg",
  ];

  const staticOffers = [
    {
      id: 1,
      image: "/image/1.avif", // Replace with your actual image path
    },
    {
      id: 2,
      image: "/image/2.avif",
    },
    {
      id: 3,
      image: "/image/3.jpg",
    },
    {
      id: 4,
      image: "/image/4.jpg",
    },
    {
      id: 5,
      image: "/image/5.jpg", // Replace with your actual image path
    },
    {
      id: 6,
      image: "/image/6.jpg",
    },
    {
      id: 7,
      image: "/image/7.avif",
    },
    {
      id: 8,
      image: "/image/8.jpg",
    },
  ];

  const images = [
    "tmartfront.jpg",
    // "inside1.jpg",
    // "inside3.jpg",
    // "inside4.jpg",
    // "inside5.jpg",
  ];

  const categoryBackgrounds = {
    Dryfruit: "/image/ds.png",
    Cosmetic: "/image/cs.jpg",
    Baby: "/image/dds.png",
    Grocery: "/image/gs.png",
    "DailyNeed Items": "/image/ns.jpg",
    Crockery: "/image/css.png",
    toys: "/image/ts.png",
    "Plastics Appliance": "/image/ps.jpg",
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    dots: true, // Display dots navigation
    infinite: true, // Infinite loop
    speed: 100, // Transition speed in milliseconds (ms)
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Autoplay slides
    autoplaySpeed: 3000, // Delay between slides in milliseconds (ms)
    afterChange: (current) => setCurrentSlide(current), // Callback function after slide change
  };

  const dispatch = useDispatch();

  const alert = useAlert();

  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 25000]);
  const [category, setCategory] = useState("");

  const [ratings, setRatings] = useState(0);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  const keyword = match.params.keyword;

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };
  let count = filteredProductsCount;

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getProduct(keyword, currentPage, price, category, ratings));
  }, [dispatch, keyword, currentPage, price, category, ratings, alert, error]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="PRODUCTS - Tz MART" />
          <div className="slider-container">
            <Slider {...sliderSettings}>
              {slidee.map((imagee, index) => (
                <div key={index}>
                  <div
                    className="banner"
                    style={{
                      backgroundImage: `url(/imagee/${imagee})`,
                      // opacity: index === currentSlide ? 1 : 0,
                    }}
                  >
                    <div className="banner-content">
                      {/* <div className="banner" style={{ margin: 0, padding: 0 }}> */}
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: "24px",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Welcome to Tz MART
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          <br></br>
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              marginTop: "16px",
              fontWeight: "bold",
            }}
          >
            Top Categories
          </Typography>
          <ul
  className="categoryBox"
  style={{
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  }}
>
  {categories.map((category) => (
    <li
      className="category-link"
      key={category}
      onClick={() => setCategory(category)}
      style={{
        width: "calc(25% - 20px)",
        marginBottom: "20px",
      }}
    >
      <Card
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          position: "relative", // For aspect ratio
          aspectRatio: "1", // This makes the card square-shaped
          transition: "transform 0.3s ease",
          backgroundImage: `url(${categoryBackgrounds[category]})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <CardContent>{/* {category} */}</CardContent>
      </Card>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          fontSize: "16px",
          color: "green",
          textAlign: "center",
        }}
      >
        {category}
      </Typography>
    </li>
  ))}
</ul>

                  
          <h2 className="homeHeading" style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }}>
            Offers
          </h2>
          <div
  style={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  }}
  className="container"
  id="container"
>
  {staticOffers.map((offer) => (
    <Link
      to={`/Categorywise`}
      style={{
        width: "calc(26% - 20px)", // Adjusted for 4 items per row
        marginBottom: "20px",
        textAlign: "center",
      }}
    >
      <img
        src={offer.image}
        alt={offer.name}
        style={{
          width: "100%",
          borderRadius: "8px",
        }}
      />
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "8px" }}
      >
        {offer.name}
      </Typography>
      <Typography variant="body2">{offer.description}</Typography>
    </Link>
  ))}
</div>


          <h2 className="productsHeading" style={{ fontWeight: "bold" }}>
            Products
          </h2>

          <div className="products">
            {products &&
              products
                .filter((product) => product.category !== "Offer")
                .map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
          </div>


          
          {resultPerPage < count && (
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}

          <div
            style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          >
            <div className="row" style={{ marginBottom: "4px" }}>
              <div className="col-lg-12">
                <div className="section-head-style-one">
                  <h2>What are you looking for?</h2>
                  <p>We have a variety of categories available</p>
                  <Slider {...sliderSettings}>
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={`/image/${image}`}
                        alt={`Image ${index + 1}`}
                        style={{ width: "10%", height: "10%" }}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>

        </Fragment>
      )}
    </Fragment>
  );
};

export default Products;





// import React, { Fragment, useEffect, useState } from "react";
// import "./Products.css";
// import "../Home/Home.css";
// import "./Home.css";
// import { useSelector, useDispatch } from "react-redux";
// import { clearErrors, getProduct } from "../../actions/productAction";
// import Loader from "../layout/Loader/Loader";
// import ProductCard from "../Home/ProductCard";
// import Offer from "../Home/Offer.js";
// import Pagination from "react-js-pagination";
// import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
// import MetaData from "../layout/MetaData";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// const categories = [
//   "Dryfruit",
//   "Cosmetic",
//   "Baby",
//   "Grocery",
//   "DailyNeed Items",
//   "Crockery",
//   "toys",
//   "Plastics Appliance",
// ];

// const Products = ({ match }) => {
//   const slidee = [
//     "inside4.jpg",
//     "inside3.jpg",
//     "inside1.jpg",
//     "f1.jpg",
//     "inside5.jpg",
//   ];

//   const images = [
//     "tmartfront.jpg",
//     // "inside1.jpg",
//     // "inside3.jpg",
//     // "inside4.jpg",
//     // "inside5.jpg",
//   ];

//   const categoryBackgrounds = {
//     Dryfruit: "/image/ds.png",
//     Cosmetic: "/image/cs.jpg",
//     Baby: "/image/dds.png",
//     Grocery: "/image/gs.png",
//     "DailyNeed Items": "/image/ns.png",
//     Crockery: "/image/css.png",
//     toys: "/image/ts.png",
//     "Plastics Appliance": "/image/ps.jpg",
//   };

//   const [currentSlide, setCurrentSlide] = useState(0);

//   const sliderSettings = {
//     dots: true, // Display dots navigation
//     infinite: true, // Infinite loop
//     speed: 100, // Transition speed in milliseconds (ms)
//     slidesToShow: 1, // Number of slides to show at a time
//     slidesToScroll: 1, // Number of slides to scroll at a time
//     autoplay: true, // Autoplay slides
//     autoplaySpeed: 3000, // Delay between slides in milliseconds (ms)
//     afterChange: (current) => setCurrentSlide(current), // Callback function after slide change
//   };

//   const dispatch = useDispatch();

//   const alert = useAlert();

//   const [currentPage, setCurrentPage] = useState(1);
//   const [price, setPrice] = useState([0, 25000]);
//   const [category, setCategory] = useState("");

//   const [ratings, setRatings] = useState(0);

//   const {
//     products,
//     loading,
//     error,
//     productsCount,
//     resultPerPage,
//     filteredProductsCount,
//   } = useSelector((state) => state.products);

//   const keyword = match.params.keyword;

//   const setCurrentPageNo = (e) => {
//     setCurrentPage(e);
//   };

//   const priceHandler = (event, newPrice) => {
//     setPrice(newPrice);
//   };
//   let count = filteredProductsCount;

//   useEffect(() => {
//     if (error) {
//       alert.error(error);
//       dispatch(clearErrors());
//     }

//     dispatch(getProduct(keyword, currentPage, price, category, ratings));
//   }, [dispatch, keyword, currentPage, price, category, ratings, alert, error]);

//   return (
//     <Fragment>
//       {loading ? (
//         <Loader />
//       ) : (
//         <Fragment>
//           <MetaData title="PRODUCTS - Tz MART" />
//           <div className="slider-container">
//             <Slider {...sliderSettings}>
//               {slidee.map((imagee, index) => (
//                 <div key={index}>
//                   <div
//                     className="banner"
//                     style={{
//                       backgroundImage: `url(/imagee/${imagee})`,
//                       // opacity: index === currentSlide ? 1 : 0,
//                     }}
//                   >
//                     <div className="banner-content">
//                       {/* <div className="banner" style={{ margin: 0, padding: 0 }}> */}
//                       <p
//                         style={{
//                           margin: 0,
//                           padding: 0,
//                           fontSize: "24px",
//                           color: "white",
//                           fontWeight: "bold",
//                         }}
//                       >
//                         Welcome to Tz MART
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>

//           <br></br>
//           <Typography
//             variant="h5"
//             style={{
//               textAlign: "center",
//               marginTop: "16px",
//               fontWeight: "bold",
//             }}
//           >
//             Top Categories
//           </Typography>
//           <ul
//             className="categoryBox"
//             style={{
//               listStyleType: "none",
//               padding: 0,
//               margin: 0,
//               display: "flex",
//               flexWrap: "wrap",
//               justifyContent: "space-between",
//             }}
//           >
//             {categories.map((category) => (
//               <li
//                 className="category-link"
//                 key={category}
//                 onClick={() => setCategory(category)}
//                 style={{
//                   width: "calc(25% - 20px)",
//                   marginBottom: "20px",
//                 }}
//               >
//                 <Card
//                   style={{
//                     border: "1px solid #ccc",
//                     borderRadius: "8px",
//                     height: "80px",
//                     transition: "transform 0.3s ease",
//                     backgroundImage: `url(${categoryBackgrounds[category]})`, // Replace "/path/to/abc.png" with the actual path to your image
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     backgroundPosition: "center",
//                   }}
//                 >
//                   <CardContent>{/* {category} */}</CardContent>
//                 </Card>
//                 <Typography
//                   variant="body1"
//                   style={{
//                     fontWeight: "bold",
//                     fontSize: "16px",
//                     color: "green",
//                     textAlign: "center",
//                   }}
//                 >
//                   {category}
//                 </Typography>
//               </li>
//             ))}
//           </ul>
                  
//           <h2 className="homeHeading" style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }}>
//             Offers
//           </h2>
//           <div style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }} className="container" id="container">
//             {products &&
//               products
//                 .filter((product) => product.category === "Offer")
//                 .slice(0, 8) // Take only the first 8 offers
//                 .map((product) => (
//                   <Offer
//                     key={product._id}
//                     product={product}
//                     targetCategory="Offer" // Replace with your actual target category
//                   />
//                 ))}
//           </div>

//           <h2 className="productsHeading" style={{ fontWeight: "bold" }}>
//             Products
//           </h2>

//           <div className="products">
//             {products &&
//               products
//                 .filter((product) => product.category !== "Offer")
//                 .map((product) => (
//                   <ProductCard key={product._id} product={product} />
//                 ))}
//           </div>

//           <div
//             style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
//           >
//             <div className="row" style={{ marginBottom: "4px" }}>
//               <div className="col-lg-12">
//                 <div className="section-head-style-one">
//                   <h2>What are you looking for?</h2>
//                   <p>We have a variety of categories available</p>
//                   <Slider {...sliderSettings}>
//                     {images.map((image, index) => (
//                       <img
//                         key={index}
//                         src={`/image/${image}`}
//                         alt={`Image ${index + 1}`}
//                         style={{ width: "10%", height: "10%" }}
//                       />
//                     ))}
//                   </Slider>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {resultPerPage < count && (
//             <div className="paginationBox">
//               <Pagination
//                 activePage={currentPage}
//                 itemsCountPerPage={resultPerPage}
//                 totalItemsCount={productsCount}
//                 onChange={setCurrentPageNo}
//                 nextPageText="Next"
//                 prevPageText="Prev"
//                 firstPageText="1st"
//                 lastPageText="Last"
//                 itemClass="page-item"
//                 linkClass="page-link"
//                 activeClass="pageItemActive"
//                 activeLinkClass="pageLinkActive"
//               />
//             </div>
//           )}
//         </Fragment>
//       )}
//     </Fragment>
//   );
// };

// export default Products;