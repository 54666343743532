import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@material-ui/lab";
import AddToCartButton from "../Home/AddToCartButton"


const Offer = ({ product, targetCategory }) => {
  // Check if the product's category matches the targetCategory
  if (product.category === targetCategory) {
    const options = {
      value: product.ratings,
      readOnly: true,
      precision: 0.5,
    };

    return (
      <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Link className="productCard" to={`/product/${product._id}`} >
      {product.offerr && ( // Check if there is an offer
      <div style={{ position: "absolute", top: 0, right: 0, background: "red", color: "white", padding: "1px 2px" }}>
        {product.offerr} SAVE
      </div>
    )}
        <img src={product.images[0].url} alt={product.name} />
        <p>{product.name}</p>
        <span>MRP= <del>{`₹${product.ourprice}`}</del></span>
      <a>Tz MART= {`₹${product.price}`}</a>
        <AddToCartButton productId={product._id} style={{ marginTop: "0px" }} />
      </Link>
    </div>
    );
  }

  // If the category doesn't match, return null (or any other placeholder)
  return null;
};

export default Offer;
