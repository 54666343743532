import React, { useState, Fragment } from "react";
import MetaData from "../layout/MetaData";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Navbar = ({ history }) => {
  const [keyword, setKeyword] = useState("");
  const { cartItems } = useSelector((state) => state.cart);

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/products/${keyword}`);
    } else {
      history.push("/products");
    }
  };

   // Function to handle cart redirection
   const handleCart = () => {
    history.push("/cart");
  };

   // Function to close the navbar after opening
   const closeNavbar = () => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');
    navbarToggler.classList.add('collapsed');
    navbarCollapse.classList.remove('show');
  };

  return (
    <>
      <Fragment>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light"
          style={{ padding: 0, position: "sticky", top: 0, zIndex: 1000 }}
        >
          <div className="container-fluid">
            {/* This div will be hidden on screens larger than large (lg) */}
            {/* <Link className="btn btn-outline-dark me-2" to="/login">
              Login
            </Link> */}

            <div className="cart-icon" onClick={handleCart}>
                <ShoppingCartIcon
                onClick={closeNavbar}
                  style={{ color: cartItems.length > 0 ? "tomato" : "unset" }}
                />
                <span onClick={closeNavbar}
>{`Cart(${cartItems.length})`}</span>
              </div>

            <div className="d-lg-none">
              {" "}
              {/* This div will be hidden on screens larger than large (lg) */}
              <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                <img
                  src="/image/logott.png"
                  alt=""
                  width="40"
                  height="38"
                  style={{ margin: 2 }}
                />
              </Link>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={closeNavbar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={closeNavbar}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/products" onClick={closeNavbar}>
                    Offers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/support" onClick={closeNavbar}>
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/aboutus" onClick={closeNavbar}>
                    About Us
                  </Link>
                </li>
              </ul>

              <div className="d-none d-lg-block" style={{ marginRight: "220px" }}>
                <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                  {" "}
                  <img
                    src="/image/logott.png"
                    alt=""
                    width="90"
                    height="78"
                    className="d-inline-block align-text-top"
                    style={{ margin: 2 }}
                  />
                </Link>
              </div>

              <div className="d-flex flex-column align-items-center">
                <div
                  className="phone-number m-0"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                   <a
                  href="tel:+919226724260"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="phone-number m-0"
                  onClick={closeNavbar}
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  <p className="m-2">Call us: 9226724260</p>
                </a>
                </div>
                <form
                  className="searchBox d-flex align-items-center"
                  onSubmit={searchSubmitHandler}
                >
                  <input
                    className="form-control me-2"
                    type="text"
                    placeholder="Search a Product ..."
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <input
                    className="btn btn-outline-success me-2"
                    type="submit"
                    value="Search"
                    onClick={closeNavbar}
                  />
                  <Link className="btn btn-outline-dark me-2" to="/login" onClick={closeNavbar}>
                    Login{" "}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </nav>
        <MetaData title="Search A Product - Tz MART" />
      </Fragment>
    </>
  );
};

export default Navbar;






// import React, { useState, Fragment } from "react";
// import MetaData from "../layout/MetaData";
// import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

// const Navbar = ({ history }) => {
//   const [keyword, setKeyword] = useState("");

//   const searchSubmitHandler = (e) => {
//     e.preventDefault();
//     if (keyword.trim()) {
//       history.push(`/products/${keyword}`);
//     } else {
//       history.push("/products");
//     }
//   };

//   return (
//     <>
//       <Fragment>
//         <nav
//           className="navbar navbar-expand-lg navbar-light bg-light"
//           style={{ padding: 0 }}
//         >
//           <div className="container-fluid">
//             {/* This div will be hidden on screens larger than large (lg) */}
//             <Link className="btn btn-outline-dark me-2" to="/login">
//               Login
//             </Link>

//             <div className="d-lg-none">
//               {" "}
//               {/* This div will be hidden on screens larger than large (lg) */}
//               <Link className="navbar-brand" to="/">
//                 <img
//                   src="/image/logott.png"
//                   alt=""
//                   width="40"
//                   height="38"
//                   style={{ margin: 2 }}
//                 />
//               </Link>
//             </div>

//             <button
//               className="navbar-toggler"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#navbarSupportedContent"
//               aria-controls="navbarSupportedContent"
//               aria-expanded="false"
//               aria-label="Toggle navigation"
//             >
//               <span className="navbar-toggler-icon"></span>
//             </button>
//             <div
//               className="collapse navbar-collapse"
//               id="navbarSupportedContent"
//             >
//               <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                 <li className="nav-item">
//                   <Link className="nav-link" to="/">
//                     Home
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link className="nav-link" to="/products">
//                     Offers
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link className="nav-link" to="/contact">
//                     Contact
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link className="nav-link" to="/about">
//                     About
//                   </Link>
//                 </li>
//               </ul>

//               <div className="" style={{ marginRight: "220px" }}>
//                 <Link className="navbar-brand" to="/">
//                   {" "}
//                   {/* Added me-4 class for margin-right */}
//                   <img
//                     src="/image/logott.png"
//                     alt=""
//                     width="90"
//                     height="78"
//                     className="d-inline-block align-text-top"
//                     style={{ margin: 2 }}
//                   />
//                 </Link>
//               </div>

//               <div className="d-flex flex-column align-items-center">
//                 <div
//                   className="phone-number m-0"
//                   style={{ color: "green", fontWeight: "bold" }}
//                 >
//                   <p className="m-2">Call us: 9226724260</p>
//                 </div>
//                 <form
//                   className="searchBox d-flex align-items-center"
//                   onSubmit={searchSubmitHandler}
//                 >
//                   <input
//                     className="form-control me-2"
//                     type="text"
//                     placeholder="Search a Product ..."
//                     onChange={(e) => setKeyword(e.target.value)}
//                   />
//                   <input
//                     className="btn btn-outline-success me-2"
//                     type="submit"
//                     value="Search"
//                   />
//                   <Link className="btn btn-outline-dark me-2" to="/login">
//                     Login{" "}
//                   </Link>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </nav>
//         <MetaData title="Search A Product -- ECOMMERCE" />
//       </Fragment>
//     </>
//   );
// };

// export default Navbar;






// import React, { useState, Fragment } from "react";
// import MetaData from "../layout/MetaData";
// // import "./Search.css";
// import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

// const Navbar = ({ history }) => {
//   const [keyword, setKeyword] = useState("");

//   const searchSubmitHandler = (e) => {
//     e.preventDefault();
//     if (keyword.trim()) {
//       history.push(`/products/${keyword}`);
//     } else {
//       history.push("/products");
//     }
//   };

//   return (
//     <>
//      <Fragment>
//   <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ height: '105px' }}>
//     <div className="container-fluid">
//       <button
//         className="navbar-toggler"
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#navbarSupportedContent"
//         aria-controls="navbarSupportedContent"
//         aria-expanded="false"
//         aria-label="Toggle navigation"
//       >
//         <span className="navbar-toggler-icon"></span>
//       </button>

//       <div className="collapse navbar-collapse" id="navbarSupportedContent">
//         <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ marginInline: 30 }}>
//           <Link className="nav-item nav-link active" to="/">
//             Home
//           </Link>
//           <Link className="nav-item nav-link active" to="/products">
//             Products
//           </Link>
//           <Link className="nav-item nav-link active" to="/contact">
//             Contact
//           </Link>
//           <Link className="nav-item nav-link active" to="/about">
//             About
//           </Link>
//         </ul>

//         <Link className="navbar-brand" to="/">
//           <div
//             className="d-flex flex-column align-items-center"
//             style={{ marginLeft: "-45vmax" }}
//           >
//             <p style={{ margin: 0 }}>Tz MART</p>
//             <img
//               src="/image/ttt.png"
//               alt=""
//               width="80"
//               height="68"
//               className="d-inline-block align-text-top"
//               style={{ margin: 0 }}
//             />
//           </div>
//         </Link>

//         <div className="d-flex flex-column align-items-center">
//           <div className="phone-number mt-2" style={{ color: "green", fontWeight: "bold" }}>
//             <p className="m-0">Call us: 9226724260</p>
//           </div>
//           <form
//             className="searchBox d-flex align-items-center"
//             onSubmit={searchSubmitHandler}
//           >
//             <input
//               className="form-control me-2"
//               type="text"
//               placeholder="Search a Product ..."
//               onChange={(e) => setKeyword(e.target.value)}
//             />
//             <input
//               className="btn btn-outline-success me-2"
//               type="submit"
//               value="Search"
//             />
//             <Link className="btn btn-outline-dark me-2" to="/login">
//               Login{" "}
//             </Link>
//           </form>
//         </div>
//       </div>
//     </div>
//   </nav>
//   <MetaData title="Search A Product -- ECOMMERCE" />
// </Fragment>

//     </>
//   );
// };

// export default Navbar;
