import React from "react";
import { Link } from 'react-router-dom';
import playStore from "../../../images/playstore.png";
import appStore from "../../../images/Appstore.png";
import "./Footer.css";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();

  const redirectToWhatsApp = () => {
    // Replace this number with the actual WhatsApp number you want to redirect to
    const phoneNumber = "9226724260";

    // Construct the WhatsApp link
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

    // Redirect to WhatsApp
    window.location.href = whatsappLink;
  };
// 1300<50;
// 1300>25;
// 2600>free

// dailyneeds;
// cosmetics;
// dryfruits;
// crockery;
// grocery;
// toys;
// plastics appliances;
// diapers;

  return (
    <>
      
      <footer id="footer">
        <div className="leftFooter">
          <h4>DOWNLOAD OUR FUTURE APP</h4>
          <h4>Download App for Android and IOS mobile phone</h4>
          <img src={playStore} alt="playstore" />
          <img src={appStore} alt="Appstore" />
        </div>

        <div className="midFooter">
          <h1>Tz MART</h1>
          <p>Your Gateway to Great Shopping</p>

          <p>Designed & Developed by InfinitiGrowIT Solutions</p>
        </div>

        <div className="rightFooter">
          <h4>Pages</h4>
          <Link to={'/support'} className="text-gray-600 hover:text-gray-800">Support</Link>
          <Link to={'/terms-of-service'} className="text-gray-600 hover:text-gray-800">Terms of Service</Link>
          <Link to={'/privacy-policy'} className="text-gray-600 hover:text-gray-800">Privact Policy</Link>
          <Link to={'/cancellation'} className="text-gray-600 hover:text-gray-800">Cancellation</Link>
        </div>

        <div className="rightFooter">
          <h4>Follow Us</h4>
          <a href="https://www.instagram.com/t_z_mart?igsh=cjA0NHl3em5xODR1">
            Instagram
          </a>
          <a onClick={redirectToWhatsApp}>WhatsApp</a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
