import React from "react";
import "./aboutSection.css";
import { Button, Typography, Avatar } from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
const About = () => {
  const visitInstagram = () => {
    window.location =
      "https://www.instagram.com/t_z_mart?igsh=cjA0NHl3em5xODR1";
  };

  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "10px", // Adding margin bottom for spacing between headings
    marginTop: "20px", // Adding margin top for spacing from the top
  };

  const paragraphStyle = {
    marginBottom: "20px", // Adding margin bottom for spacing between paragraphs
    lineHeight: "1.5", // Adjusting line height for readability
  };
  return (
    <div>
      <div>
        <div className="privacy-policy" style={{ padding: "30px", fontFamily: "Arial, sans-serif" }}>
          <h1 style={headingStyle}>About Us</h1>
          <p style={paragraphStyle}>
            Welcome to Tz Mart, your one-stop destination for your grocery
            needs! Established with a commitment to serve our community with
            convenience and quality, Tz Mart is your trusted online Kirana shop,
            as we are Indians, we believe the quality of products. At Tz MART, we are committed to
            delivering the highest quality products directly from our shop to our customers' doorsteps, eliminating middlemen and
            ensuring ethical production and procurement practices. At Tz Mart,
            we understand the importance of convenience and reliability when it
            comes to your grocery shopping. We strive to provide a seamless
            online shopping experience, ensuring that you have access to a wide
            range of products right at your fingertips. We take pride in
            sourcing high-quality products from trusted suppliers and brands,
            guaranteeing freshness and authenticity with every purchase. From
            locally sourced goods to international favorites, we curate our
            inventory to cater to diverse tastes and preferences.<br></br>
            <br></br>
            <h1 style={headingStyle}>Our Mission</h1>
            Our mission is to simplify your life by offering a diverse selection
            of groceries, household essentials, and daily necessities, all
            available at competitive prices. Whether you're looking for fresh
            produce, pantry staples, personal care items, or household cleaners,
            we've got you covered..<br></br>
            <br></br>
            <h1 style={headingStyle}>Our Vision</h1>
            At Tz Mart, our vision is to revolutionize the way people shop for
            groceries, bringing convenience, affordability, and quality to every
            household. We aspire to become the premier destination for online
            Kirana shopping, setting new standards of excellence in customer
            service, product diversity, and community engagement..
          </p>
        </div>
        <div
          style={{ position: "relative", width: "100%", height: "500px" }}
          className="map-container"
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "90%",
              borderRadius: "20px",
            }}
            className="mapBg"
          ></div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              width: "90%",
              height: "90%",
            }}
            className="map"
          >
            <iframe
              src="https://maps.google.com/maps?q=tz+mart+kat+kat+gate+aurangbad&t=&z=20&ie=UTF8&iwloc=&output=embed"
              width="100%"
              height="450"
              style={{ border: "0", marginLeft: "3.5vmax" }} // Add margin-left here
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
