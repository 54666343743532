import React from "react";
import { Link } from "react-router-dom";

const Offer = ({ product, targetCategory }) => {
  // Check if the product's category matches the targetCategory
  if (product.category === targetCategory) {
    const options = {
      value: product.ratings,
      readOnly: true,
      precision: 0.5,
    };

    return (
      <Link className="productCard" to={`/Categorywise`}>
        <img src={product.images[0].url} alt={product.name} />
        {/* <p>{product.name}</p> */}
      </Link>
    );
  }

  // If the category doesn't match, return null (or any other placeholder)
  return null;
};

export default Offer;
