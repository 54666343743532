import React from "react";


const Refund = () => {
  const headingStyle = {
    fontWeight: "bold",
  };

  return (
    <div className="privacy-policy">
      <h1 style={headingStyle}>Cancellation and Refund Policy</h1>
      <p>
      Thank you for visiting our website (www.tzmart.in). This Refund Policy outlines the terms and conditions related to refunds for our services. Please read this policy carefully before making any purchases or transactions on our website.
      </p>
      <h2 style={headingStyle}>Return product payment:</h2>
      <p>
      All Return product payment are refundable.
In case of cancellation of the product by the customer, a refund will be provided to the registered customer.
      </p>
      
      <h2 style={headingStyle}>Cookies</h2>
      <p>
        We use the information we collect in various ways, including to:
        Provide, operate, and maintain our website Improve, personalize, and
        expand our website Understand and analyze how you use our website
        Develop new products, services, features, and functionality Communicate
        with you, either directly or through one of our partners, including for
        customer service, to provide you with updates and other information
        relating to the website, and for marketing and promotional purposes Send
        you emails Find and prevent fraud
      </p>
      <h2 style={headingStyle}>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Thus, we advise you
        to review this page periodically for any changes. We will notify you of
        any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately, after they are posted on this page.
        Contact Us
      </p>
    </div>
  );
};

export default Refund;
